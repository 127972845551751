<template>
  <main>
    <CForm>
      <CCard>
        <CCardBody class="d-flex justify-content-between">
          <div>
            <h5 class="text-info">
              啟用會員續期條件設定
            </h5>
            <div>
              啟用後可設定會員等級資格時效，以及可於各等級內設定會員等級資格續期需要的消費金額。<br>
            </div>
            <div v-if="ShopSetting.EnableMemberRenewSetting" class="mt-4">
              <CInput :label="'會員資格時效'" type="number" v-model="ShopSetting.EnableMemberRenewTime"
                      :placeholder="'未填寫則為預設值'" :description="'預設為一年，當會員升等時資格保留的期限長度，若未設定「續期金額」則會以該等級的「消費金額」為判斷是否保留會員資格依據。'">
                <template #append-content>
                  年
                </template>
              </CInput>
              <CInput :label="'即將過期通知'" type="number" v-model="ShopSetting.LevelExpiredNotifyTime"
                      :placeholder="'若未填寫則預設為30天前通知'" :description="'預設為30天前通知會員等級即將過期。'">
                <template #append-content>
                  天前通知
                </template>
              </CInput>
            </div>
            <CButton color="success" class="px-4 mt-3" @click="SetShopSetting()" size="sm">
              {{ $t('Global.Update') }}
            </CButton>
          </div>
          <CSwitch color="success" :checked.sync="ShopSetting.EnableMemberRenewSetting" />
        </CCardBody>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
      </CCard>
      <CCard>
        <CCardBody class="d-flex justify-content-between">
          <div>
            <h5 class="text-info">
              會員等級變更觸發條件
            </h5>
            <div>
              設定會員於付款完成或取貨時才觸發會員升級或降級。<br>
            </div>
            <div class="mt-4">
              <CSelect description="若未選擇預設為完成付款後觸發會員等級變更。" :label="$t('ShopSetting.CheckoutRedirectChooseMode')" :options="[{value: 'Paid', label: '完成付款後觸發(付款狀態為已付款)'}, {value: 'Arrival', label: '取貨後觸發(付款狀態為已付款且配送狀態為已取貨)'}]" v-model="ShopSetting.MemberLevelUpEventSetting" :value.sync="ShopSetting.MemberLevelUpEventSetting" :placeholder="$t('Global.PleaseSelect')">
                <template #label>
                  <label class="font-weight-bold">選擇事件</label>
                </template>
              </CSelect>
              <CInput :label="'延遲升級天數'" type="number" v-model="ShopSetting.DelayMemberLevelUpDay"
                      :placeholder="'未填寫或填0則為預設為立即觸發'" :description="'預設為立即觸發，啟用延遲升級以後，系統會於每日凌晨2:00開始進行檢查升級作業。'">
                <template #append-content>
                  天
                </template>
              </CInput>
            </div>
            <CButton color="success" class="px-4 mt-3" @click="SetShopSetting()" size="sm">
              {{ $t('Global.Update') }}
            </CButton>
          </div>
        </CCardBody>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
      </CCard>
      <CCard>
        <CCardBody class="d-flex justify-content-between">
          <div>
            <h5 class="text-info">
              會員等級設置
            </h5>
            <div>
              新增會員等級以設置對應等級的商品折扣比例、購物金獲得比例及升級條件。
            </div>
          </div>
          <CButton color="success" variant="outline" shape="pill" type="button" @click="OpenModal()" style="height: 35px;">
            {{ $t('Global.Add') + '等級' }}
          </CButton>
        </CCardBody>
        <CCardBody class="p-0">
          <CDataTable
              id="MemberList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Level="{index}">
              <td class="text-center">
                {{ index + 1 }}
              </td>
            </template>
            <template #ShopDiscount="{item}">
              <td>
                {{ item.ShopDiscount }}%
              </td>
            </template>
            <template #GoldDiscount="{item}">
              <td>
                {{ item.GoldDiscount }}%
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === true ? 'success' : 'danger')">
                  {{ $t('Logistics.StatusType.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Condition="{item}">
              <td>
                <span v-if="item.NextFullAmount === 'max'">
                  消費金額 <b class="text-danger">${{ item.FullAmount }}</b> 以上
                </span>
                <span v-else>
                   消費金額 <b class="text-danger">${{ item.FullAmount }} ~ ${{ item.NextFullAmount }}</b>
                </span>
              </td>
            </template>
            <template #RenewAmount="{item}">
              <td>
                {{ item.RenewAmount || '--' }}
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <template v-if="item.ID !== 0">
                  <CButton color="info" size="sm" class="mr-1" @click="GetDetail(item.ID)">
                    <CIcon name="cil-pencil" class="c-icon-custom-size"/>
                  </CButton>
                  <CButton color="danger" size="sm" @click="Delete(item.ID)">{{ $t('Global.Delete') }}</CButton>
                </template>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CForm>
    <CModal id="LevelDataModal" class="ActionModel" :show.sync="LevelDataModal" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ (Action === 'Edit' ? '更新' : '新增') + '會員等級' }}</h5>
          <CButtonClose @click="LevelDataModal = false"/>
        </header>
      </template>
      <CTabs :active-tab="0" :key="SelectID" fade addTabsWrapperClasses="tab-info">
        <CTab :title="'基礎設定'">
          <CInput horizontal v-model="LevelData.Name" :label="'等級名稱'" />
          <CInput horizontal v-model="LevelData.ShopDiscount" type="number" :label="'商品折扣比例'">
            <template #append-content>
              <span>%</span>
            </template>
          </CInput>
          <CInput horizontal v-model="LevelData.GoldDiscount" type="number" :label="'購物金獲得比例'">
            <template #append-content>
              <span>%</span>
            </template>
          </CInput>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              狀態
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="LevelData.Status"/>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="'條件設定'">
          <CInput horizontal v-model="LevelData.FullAmount" type="number" :label="'消費金額'">
            <template #append-content>
              <span>元</span>
            </template>
          </CInput>
          <CInput v-if="Features.includes('MemberRenew')" horizontal v-model="LevelData.RenewAmount" type="number" :label="'續期消費金額'">
            <template #append-content>
              <span>元</span>
            </template>
          </CInput>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true) || (Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="LevelDataModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="SetData()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="DeleteData()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "會員等級"
}
}
</route>

<script>
export default {
  name: 'MemberLevel',
  layout: 'manage',
  components: {
  },
  data() {
    return {
      Features: [],
      List: [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ],
      LevelData: {
        Name: '',
        ShopDiscount: 100,
        GoldDiscount: 0,
        FullAmount: 0,
        Status: false
      },
      ShopSetting: {},
      SelectID: null,
      Action: 'Add',
      AlertModel: false,
      LevelDataModal: false,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      }
    }
  },
  computed: {
    Field() {
      const DefaultField = [
        {key: 'Level', label: ''},
        {key: 'Name', label: '等級名稱'},
        {key: 'ShopDiscount', label: '商品折扣比例'},
        {key: 'GoldDiscount', label: '購物金獲得比例'},
        {key: 'Condition', label: '條件區間'},
      ]
      if (this.Features.includes('MemberRenew') && this.ShopSetting.EnableMemberRenewSetting) {
        DefaultField.push(
          {key: 'RenewAmount', label: '續期金額'}
        )
      }
      DefaultField.push(
        {key: 'Status', label: '狀態'},
        {key: 'Action', label: ''}
      )
      return DefaultField
    },
  },
  created() {
    this.Features = this.$store.state.user.permission.Features.Organization ? (this.$store.state.user.permission.Features.Organization.Member || []) : []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  watch: {
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
        this.GetShopSetting()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetShopSetting () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/ShopSetting',
        method: 'post'
      }).then(({data}) => {
        this.ShopSetting = data
        return true
      }).catch((err) => {
        throw err
      })
    },
    SetShopSetting () {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/set/ShopSetting',
        method: 'post',
        data: {
          ShopSetting: this.ShopSetting
        }
      }).then(() => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    GetList() {
      this.Loading = true
      this.List = [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/list',
        method: 'post'
      }).then(({data}) => {
        this.Loading = false
        const TempList = []
        Object.values(data).forEach((item) => {
          TempList.push(item)
        })
        TempList.sort((a, b) => a.FullAmount - b.FullAmount)
        this.List = this.List.concat(TempList).map((item, index) => {
          return {
            ...item,
            NextFullAmount: index === TempList.length ? 'max' : parseInt(TempList[index].FullAmount) - 1
          }
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    OpenModal () {
      this.LevelDataModal = true
      this.Action = 'Add'
      this.SelectID = null
      this.LevelData = {
        Name: '',
        ShopDiscount: 100,
        GoldDiscount: 0,
        FullAmount: 0,
        Status: false
      }
    },
    GetDetail(ID) {
      this.Submit = true
      this.LevelDataModal = true
      this.Action = 'Edit'
      this.SelectID = ID
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/get',
        method: 'post',
        data: {
          ID
        }
      }).then(({data}) => {
        this.Submit = false
        this.LevelData = data
      }).catch((err) => {
        this.Submit = false
        this.LevelDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    SetData () {
      let url = '/user/level/set'
      if (this.Action === 'Add') {
        url = '/user/level/add'
      }
      // 判斷條件是否大於當前最大條件
      // if (parseFloat(this.LevelData.FullAmount) <= Math.max(...this.List.map(item => parseFloat(item.FullAmount)))) {
      //   this.$notify({
      //     group: 'notify',
      //     title: this.$t('Message.Warning'),
      //     text: '消費金額需設定大於' + Math.max(...this.List.map(item => parseFloat(item.FullAmount))) + '元',
      //     type: 'warn'
      //   })
      //   return false
      // }
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url,
        method: 'post',
        data: {
          ID: this.SelectID,
          LevelData: this.LevelData
        }
      }).then(() => {
        this.GetList()
        this.Submit = false
        this.LevelDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: (this.Action === 'Add' ? '新增' : '編輯') + '會員等級成功',
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.LevelDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    Delete (ID) {
      this.SelectID = ID
      this.AlertModel = true
    },
    DeleteData () {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/delete',
        method: 'post',
        data: {
          ID: this.SelectID
        }
      }).then(() => {
        this.Loading = false
        this.GetList()
        this.AlertModel = false
      }).catch((error) => {
        this.Loading = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#MemberList  {
  thead {
    th {
      white-space: nowrap;
      &:first-child {
        text-align: center;
      }
    }
  }
  tbody {
    td {
      white-space: nowrap;
      &:first-child {
        vertical-align: middle;
      }
    }
  }
}
</style>
